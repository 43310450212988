import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from '../../../components/SEO'
import Layout from '../../../components/totem/layout'
import Comparador from '../../../components/totem/Comparador'
import ErrorBoundary from '../../../components/ErrorBoundary'
import * as S from '../comparador/styles'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'

const ComparadorPage = ({
  data: {
    allProduto: { edges: produtos },
  },
  pageContext: { loja },
}) => { 
  const { t } = useTranslation()

  useEffect(() => {
    ditoTrack('acessou-comparador')
  }, []);
  return (
    <div>
      <Layout loja={loja}>
        <SEO
          title={t('comparador_title_seo')}
          description={t('description_seo')}
        />
        <S.Bg>
          <S.PageTitle>
            <h1>
              <Trans>
                Está em dúvida sobre<br></br> o seu tênis ideal?
              </Trans>
            </h1>
            <p>
              <Trans>Faça um comparativo entre os seus favoritos.</Trans>
            </p>
          </S.PageTitle>
          <ErrorBoundary>
            <Comparador loja={loja} produtos={produtos} />
          </ErrorBoundary>
        </S.Bg>
        <BtnVoltar></BtnVoltar>
      </Layout>
    </div>
  )
}

ComparadorPage.propTypes = {
  data: PropTypes.shape({
    allProduto: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            cabedal: PropTypes.string,
            drop: PropTypes.string,
            entressola: PropTypes.string,
            foto: PropTypes.string,
            id: PropTypes.string.isRequired,
            alternative_id: PropTypes.number.isRequired,
            indicadoPara: PropTypes.string,
            nome: PropTypes.string.isRequired,
            preco: PropTypes.string,
            slug: PropTypes.string,
            tipoPisada: PropTypes.string,
            solado: PropTypes.string,
            peso: PropTypes.string,
            urlComprar: PropTypes.string,
            localFoto: PropTypes.object.isRequired,
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
export default ComparadorPage

export const query = graphql`
  query ($language: String!, $slugLoja: String) {
    allProduto(
      filter: {
        alternative_id: { ne: 0 }
        lojas: { elemMatch: { slug: { eq: $slugLoja } } }
      }
      sort: { fields: nome }
    ) {
      edges {
        node {
          ...produtoCampos
          ...produtoFotoComparador
          pais
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
