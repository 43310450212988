import styled from 'styled-components'

export const Button = styled.button`
  align-items: center;
  background-color: ${({ isCumulus23 }) =>
    isCumulus23 ? '#0485c8' : 'var(--primary-color)'};
  border: none;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'ASICSFont3.0-Regular';
  font-size: 1.1rem;
  font-style: italic;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.063rem;
  padding: 0.625rem 1.7rem;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    font-size: 1.125rem;
    padding: 0.5rem 1.7rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(:disabled) {
    background-color: var(--secondary-color);
  }

  .circleLoading {
    color: #fff !important;
    height: 25px !important;
    margin-right: 10px;
    width: 25px !important;
  }
`

/*
  Referência:
  https://codepen.io/webinspect/details/cHsld
*/
export const Share = styled.span`
  background-color: var(--primary-color);
  border-radius: 1.25rem;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  font-size: 1.1rem;
  font-style: italic;
  font-weight: bold;
  padding: 0.625rem 1.7rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover a,
  &.focus a {
    transition: opacity 250ms linear, text-indent 250ms ease-out,
      line-height 250ms ease-out;
    opacity: 1;

    &.first,
    &.third {
      text-indent: 0;
    }

    &.second {
      line-height: 3.1;
      padding-top: 0;
    }
  }
`

export const LinkSocialShare = styled.a`
  background-color: var(--primary-color);
  font-size: 1.1rem !important;
  opacity: 0;
  transition: opacity 250ms linear, text-indent 250ms ease-out,
    line-height 250ms ease-out, padding-top 250ms ease-out;
  color: #fff;
  text-decoration: none;
  line-height: 3.1;
  display: inline-block;
  position: absolute;
  width: 33.33% !important;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    opacity: 1;
  }

  &:hover {
    background-color: var(--secondary-color);
  }

  &.first {
    text-indent: -60px;

    @media (max-width: 768px) {
      text-indent: initial;
      line-height: 2.5;
    }
  }

  &.second {
    line-height: 1;
    left: 33.33%;
    padding-top: 2em;

    @media (max-width: 768px) {
      padding-top: 0;
      line-height: 2.5;
    }
  }

  &.third {
    text-indent: 60px;
    left: 66.65%;

    @media (max-width: 768px) {
      text-indent: initial;
      line-height: 2.5;
    }
  }
`
